<template>
  <b-modal
    v-model="showModal"
    title="Release Notes"
    centered
    hide-footer
    @hide="onClose">
    <template slot="modal-header-close"><wbr/></template>
    <div class="container img-container release-modal-container">
      <div class="row">
        <div class="col-12 d-flex flex-column gap-20">
          <samp class="text-center">
            {{versionJson.version}} | {{versionJson.date}}
          </samp>

          <h1 class="text-center mb-2">
            Fresh out the oven 🔥
          </h1>

          <div>
            <ul>
              <li
                v-for="item in versionJson.release_notes"
                :key="item"
                class="li-point">
                <span v-html="item" class="blockquote"/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import versionJson from '@/version.json';
import {mapState} from 'vuex';

export default {
  name: 'ReleaseModal',
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      versionJson,
      showModal: false,
    };
  },
  computed: {
    ...mapState({
      user: state => (state.auth.customer || {}).user,
    }),
  },
  mounted() {
    if (!this.user) return false;

    const hasReleaseNotes = this.versionJson.release_notes.length > 0;
    const hasMatchingRole =
      !this.versionJson.roles.length ||
      this.versionJson.roles.some(role => this.user?.roles.includes(role)) ||
      this.user?.roles.includes('Administrator');
    const localVersion = localStorage.getItem('version');

    this.showModal = localVersion !== this.versionJson.version && hasReleaseNotes && hasMatchingRole;
  },
  methods: {
    onClose() {
      if (this.showModal) {
        localStorage.setItem('version', this.versionJson.version);
      }
    },
  },
};
</script>

<style>
.release-modal-container .li-point {
  margin-bottom: 4px;
  line-height: 24px;
}
</style>
